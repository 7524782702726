"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var RoadmapItem = function (_a) {
    var date = _a.date, title = _a.title, description = _a.description, active = _a.active, index = _a.index, position = _a.position;
    var offsetClass = position === "top" ? "top" : "bottom";
    return ((0, jsx_runtime_1.jsx)("div", __assign({ className: "roadmap-item" }, { children: (0, jsx_runtime_1.jsxs)("div", __assign({ className: "".concat(offsetClass, " ").concat(active && "".concat(offsetClass, "_active")) }, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ className: "date" }, { children: date })), (0, jsx_runtime_1.jsx)("div", __assign({ className: "title" }, { children: title }))] })) })));
};
exports.default = RoadmapItem;
